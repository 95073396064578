import { ReactNode } from "react";
import Typist from "react-typist";
import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  Badge,
  SwCard,
  ITabContent,
  TabContent,
  Tabs,
  GradientCard,
  ImageLazyLoader,
} from "../../components";
import { SwitchAppLocationPointer } from "./SwitchAppLocationPointer";

import { images } from "./images";
import { PaymentProviderCarousel } from "./PaymentProviderCarousel";
import { appConstants } from "../../constants";

export const View = () => {
  return (
    <main className="home-page">
      <section className="wrapper bg-gradient-blue bg-switchapp-gradient main-section mb-10 mb-md-n5">
        <div className="container pt-15 pt-md-15 pb-8">
          <div className="row gx-0 gy-10 align-items-center">
            <div
              className="col-lg-6 align-self-end mb-n5 mb-md-n10 mb-xl-n10"
              // data-cues="slideInDown"
              // data-group="page-title"
              // data-delay="600"
            >
              <h1 className="display-1 switchapp-deep-theme fs-80 mb-12">
                Business management <br /> engine for
                <br />
                the internet
                {/* <Typist>
                  <span> First Sentence </span>
                  <Typist.Backspace count={13} delay={300} />
                  <span> Phrase </span>
                </Typist> */}
                {/* <span
                  className="typer text-nowrap"
                  data-delay="100"
                  data-words="the internet, businesses, enterprises, social media, startups, individuals, government, NGOs"
                ></span>
                <span
                  className="cursor switchapp-deep-theme"
                  data-owner="typer"
                ></span> */}
              </h1>
              <p className="lead fs-16 lh-sm mb-7 pe-md-18 pe-lg-0 pe-xxl-15 text-black">
                Businesses around the globe use SwitchApp to offload operations,
                and manage complexities, so they can focus on growth
              </p>
              <div>
                <a
                  href={appConstants.appSignInUrl}
                  className="btn btn-md btn-primary me-3 mt-3 rounded"
                >
                  Sign In
                </a>
                <a
                  href={appConstants.appSignUpUrl}
                  className="btn btn-md btn-outline-primary mt-3 rounded"
                >
                  Request Invitation <i className="fa fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
            {/* <!-- /column --> */}
            <div
              className="col-lg-6 mb-n18 me-n18"
              // data-cues="slideInDown"
            >
              <div className="position-relative light-gallery-wrapper">
                <figure className="cut-off-container">
                  {/* <ImageLazyLoader
                    alt={"hero image"}
                    // height={"none"}
                    src={images.dashboardSampleScreen} // use normal <img> attributes as props
                    width={1200}
                    caption=""
                  /> */}

                  <img
                    src={images.dashboardSampleScreen}
                    alt="hero"
                    className="hero-image"
                    style={{ width: 1200, maxWidth: "none" }}
                  />
                </figure>
              </div>
              {/* <!-- /div --> */}
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light angled upper-end mt-23 mt-md-0">
        <div className="container pt-25 pb-15 pt-md-22 pb-md-21">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-2 align-items-end text-center">
            <div className="col-lg-4">
              <h2 className="fs-16 text-uppercase text-line text-primary mb-3">
                Virtually unlimited integrations
              </h2>
              <h3 className="display-4 fw-normal mb-0 pe-xxl-15">
                Out of the box
              </h3>
            </div>
            {/* <!-- /column --> */}

            <div className="col-lg-8 mt-lg-2">
              <div className="row align-items-center counter-wrapper gy-6 text-center">
                <div className="col-md-4">
                  <h3 className="counter counter-lg">50+</h3>
                  <p>Payment Providers</p>
                </div>
                {/* <!--/column --> */}
                <div className="col-md-4">
                  <h3 className="counter counter-lg">80+</h3>
                  <p>Countries</p>
                </div>
                {/* <!--/column --> */}
                <div className="col-md-4">
                  <h3 className="counter counter-lg">30+</h3>
                  <p>Configurations</p>
                </div>
                {/* <!--/column --> */}
              </div>
              {/* <!--/.row --> */}
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}

          <div className="row mt-15 carousel-container cut-off-container">
            <PaymentProviderCarousel />
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-soft-purple">
        <div className="container py-10 position-relative">
          <div className="row gy-6 mb-0 mb-md-5 justify-content-center">
            <div className="col-12 text-center">
              <h2 className="fs-16 text-uppercase text-line text-primary mb-3">
                Our Model
              </h2>
              <h3 className="display-4 fw-normal mb-3">
                How is SwitchApp different?
              </h3>
              <p>
                SwitchApp is doing a lot more than just generating revenue for
                you. We are here to open your{" "}
                <br className="d-none d-lg-block" /> business up to the world.
                We handle these for you:
              </p>
            </div>
            {/* <!--/column --> */}

            <div className="col-lg-12 globe-section">
              <SwitchAppLocationPointer
                topPercent={10}
                leftPercent={15}
                icon={
                  // <i className="uil uil-atm-card fs-40 switchapp-deep-theme"></i>
                  <img
                    src={images.bankCardOutlineIcon}
                    className="svg-inject icon-svg icon-svg-md text-yellow me-4"
                    alt="bank card"
                  />
                }
                info={
                  <>
                    Build and maintain relationships <br /> with payment
                    providers.
                  </>
                }
              />

              <SwitchAppLocationPointer
                topPercent={30}
                leftPercent={5}
                icon={
                  // <i className="uil uil-shield-check fs-40 switchapp-deep-theme"></i>
                  <img
                    src={images.shieldTickOutlineIcon}
                    className="svg-inject icon-svg icon-svg-md text-yellow me-4"
                    alt="shield tick"
                  />
                }
                info={
                  <>
                    Take accountability and notify you
                    <br /> of all fraud that occurs on your account.
                  </>
                }
              />

              <SwitchAppLocationPointer
                topPercent={50}
                leftPercent={15}
                icon={
                  // <i className="uil uil-receipt-alt fs-40 switchapp-deep-theme"></i>
                  <img
                    src={images.receiptOutlineIcon}
                    className="svg-inject icon-svg icon-svg-md text-yellow me-4"
                    alt="receipt outline"
                  />
                }
                info={
                  <>
                    Take on liability for charging and
                    <br /> remiting sales taxes, globally.
                  </>
                }
              />

              {/* Right-side locations */}
              <SwitchAppLocationPointer
                topPercent={10}
                leftPercent={70}
                className={"d-none d-md-flex"}
                icon={
                  // <i className="uil uil-analysis fs-40 switchapp-deep-theme"></i>
                  <img
                    src={images.routingOutlineIcon}
                    className="svg-inject icon-svg icon-svg-md text-yellow me-4"
                    alt="routing outline"
                  />
                }
                info={
                  <>
                    Reconcile your revenue data
                    <br /> across invoicing and payment
                    <br /> methods.
                  </>
                }
              />

              <SwitchAppLocationPointer
                topPercent={30}
                leftPercent={75}
                className={"d-none d-md-flex"}
                icon={
                  // <i className="uil uil-comment-alt-check fs-40 switchapp-deep-theme"></i>
                  <img
                    src={images.messageTickOutlineIcon}
                    className="svg-inject icon-svg icon-svg-md text-yellow me-4"
                    alt="message tick"
                  />
                }
                info={
                  <>
                    Handle all your payment
                    <br /> support inquiries.
                  </>
                }
              />

              <SwitchAppLocationPointer
                topPercent={50}
                leftPercent={70}
                className={"d-none d-md-flex"}
                icon={
                  // <i className="uil uil-user-exclamation fs-40 switchapp-deep-theme"></i>
                  <img
                    src={images.userSinalIcon}
                    className="svg-inject icon-svg icon-svg-md text-yellow me-4"
                    alt="user signal"
                  />
                }
                info={
                  <>
                    Minimize turnover by recovering
                    <br /> failed payments.
                  </>
                }
              />
            </div>
            {/* <!--/column --> */}

            <div className="col-lg-12 text-center">
              <a
                href={`${appConstants.appDashboardUrl}/signup`}
                className="btn btn-md btn-outline-primary mt-3 rounded"
              >
                Experience SwitchApp <i className="btn-chevron-placement"></i>
              </a>
            </div>
            {/* <!--/column --> */}
          </div>
          {/* row */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-soft-green mt-md-0">
        <div className="container pt-10 pb-5">
          <div className="row">
            <div className="col-lg-10">
              <h2 className="fs-16 text-uppercase text-line text-primary mb-3">
                Features
              </h2>
              <h3 className="display-4 fw-normal mb-9">
                Your all-in-one payments infrastructure
              </h3>
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}

          <div className="row gx-md-8 gy-8 mb-14 mb-md-18">
            <div className="col-md-6 col-lg-4">
              <SwCard
                className="switchapp-theme-card text-white"
                title={"Grow"}
              >
                <>
                  <p>
                    SwitchApp supports all types of sales processes, so you may
                    sell as you like. Optimize your checkout for conversions,
                    expand sales-assisted invoicing to additional business
                    accounts, and incorporate subscription billing.
                  </p>

                  <div>
                    <Badge kind="white" className="me-3 mb-5">
                      Custom checkout
                    </Badge>

                    <Badge kind="white" className="me-3 mb-5">
                      Subscription billing
                    </Badge>

                    <Badge kind="white" className="me-3 mb-5">
                      international providers
                    </Badge>
                  </div>
                </>
              </SwCard>
            </div>
            {/* <!--/column --> */}

            <div className="col-md-6 col-lg-4">
              <SwCard
                className="switchapp-theme-card text-white"
                title={"Operate"}
              >
                <>
                  <p>
                    SwitchApp ensures that you make the most revenue as possible
                    no matter where or how you sell. We route payments across
                    different payment providers, ensure 100% sales tax
                    compliance, and protect you from fraud.
                  </p>

                  <div>
                    <Badge kind="white" className="me-3 mb-5">
                      Payment Processing
                    </Badge>

                    <Badge kind="white" className="me-3 mb-5">
                      Smart routing engine
                    </Badge>

                    <Badge kind="white" className="me-3 mb-5">
                      Global tax compliance
                    </Badge>
                  </div>
                </>
              </SwCard>
            </div>
            {/* <!--/column --> */}

            <div className="col-md-6 col-lg-4">
              <SwCard
                className="switchapp-theme-card text-white"
                title={"Understand"}
              >
                <>
                  <p>
                    We function as your trustworthy source because all of your
                    revenue comes through SwitchApp, regardless of bank, region,
                    or channel. So whether you run your reports using SwitchApp
                    or connect to another application, your data is always safe.
                  </p>

                  <div>
                    <Badge kind="white" className="me-3 mb-5">
                      Metric reporting
                    </Badge>

                    <Badge kind="white" className="me-3 mb-5">
                      Retain customers
                    </Badge>

                    <Badge kind="white" className="me-3 mb-5">
                      Upsell Insights
                    </Badge>
                  </div>
                </>
              </SwCard>
            </div>
            {/* <!--/column --> */}

            <div className="col-12">
              <SwCard
                className="switchapp-theme-card text-white"
                title={"Services"}
              >
                <div className="row">
                  <div className="col-12 col-md-6">
                    <p>
                      Scaling a business requires more than just technology.
                      Customers require specialized assistance; you need pricing
                      and go-to-market strategies; and you want to migrate
                      subscription agreements seamlessly.
                    </p>
                  </div>

                  <div className="col-12 col-md-6">
                    <div>
                      <Badge kind="white" className="me-3 mb-5">
                        Custom support
                      </Badge>

                      <Badge kind="white" className="me-3 mb-5">
                        Billing support
                      </Badge>

                      <Badge kind="white" className="me-3 mb-5">
                        Implementation service
                      </Badge>

                      <Badge kind="white" className="me-3 mb-5">
                        Advisory
                      </Badge>

                      <Badge kind="white" className="me-3 mb-5">
                        Migration support
                      </Badge>
                    </div>
                  </div>
                </div>
              </SwCard>
            </div>
            {/* <!--/column --> */}
          </div>
          {/* <!--/.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-soft-green mt-md-0">
        <div className="container pt-10 pb-5">
          <div className="row">
            <div className="col-lg-10">
              <h2 className="fs-16 text-uppercase text-line text-primary mb-3">
                For Businesses
              </h2>
              <h3 className="display-4 fw-normal mb-9">
                Solution sets for every business <br /> type and size.
              </h3>
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}

          {/* <div className="row gx-md-8 gy-8 mb-14 mb-md-18">
              <div className="col-lg-6">
                <Tabs
                  name="testimonialTab"
                  tabContents={testimonialTabContents}
                  tabClassName="side-tabs"
                />
              </div>

              <div className="col-lg-6">
                <TabContent
                  name="testimonialTab"
                  tabContents={testimonialTabContents}
                  //   tabContentClassName="side-tabs-content"
                />
              </div>
            </div> */}
          {/* <!--/.row --> */}

          <div className="row gx-md-8 gy-8 mb-14 mb-md-18">
            <div className="col-lg-6">
              <ul className="switchapp-solutions-tabs nav nav-tabs nav-tabs-bg flex-column justify-content-between">
                <li className="nav-item">
                  <a
                    className="nav-link d-flex flex-row active"
                    data-bs-toggle="tab"
                    href="#tab2-1"
                  >
                    <div>
                      <img
                        src={images.shoppingCartIcon}
                        className="svg-inject icon-svg icon-svg-md text-yellow me-4"
                        alt=""
                      />
                    </div>
                    <div>
                      <h3 className="mb-1">Retail</h3>
                      <p className="fs-16 fw-light">
                        All the retail tools you require, including reports,
                        invoicing, subscriptions and more, for selling both
                        online and in-person
                      </p>
                    </div>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link d-flex flex-row"
                    data-bs-toggle="tab"
                    href="#tab2-2"
                  >
                    <div>
                      <img
                        src={images.checkListIcon}
                        className="svg-inject icon-svg icon-svg-md text-green me-4"
                        alt=""
                      />
                    </div>
                    <div>
                      <h3 className="mb-1">Professional Services</h3>
                      <p className="fs-16 fw-light">
                        Integrated solutions to help you get paid faster,
                        provide better customer service, and optimize your cash
                        flow.
                      </p>
                    </div>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link d-flex flex-row"
                    data-bs-toggle="tab"
                    href="#tab2-3"
                  >
                    <div>
                      <img
                        src={images.briefcaseIcon}
                        className="svg-inject icon-svg icon-svg-md text-green me-4"
                        alt=""
                      />
                    </div>
                    <div>
                      <h3 className="mb-1">Large Businesses</h3>
                      <p className="fs-16 fw-light">
                        With comprehensive reporting and other features, you may
                        create custom solutions and provide excellent client
                        service
                      </p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-6">
              <div className="tab-content">
                <div className="tab-pane fade show active" id="tab2-1">
                  <img
                    src={images.retail}
                    alt="testimonial image"
                    className="tabs-testimonial-image"
                  />
                </div>

                <div className="tab-pane fade" id="tab2-2">
                  <img
                    src={images.professionalServices}
                    alt="testimonial image"
                    className="tabs-testimonial-image"
                  />
                </div>

                <div className="tab-pane fade" id="tab2-3">
                  <img
                    src={images.largeBusiness}
                    alt="testimonial image"
                    className="tabs-testimonial-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}
    </main>
  );
};
