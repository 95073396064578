import { BulletTitleDescription } from "../../components";
import { SubscriptionPoint } from "./SubscriptionPoint";

import { images } from "./images";
import { appConstants } from "../../constants";

export const View = () => {
  return (
    <div className="subscriptions-page">
      <section className="wrapper bg-soft-purple main-section pb-30">
        <div className="container pt-7 pt-md-5 pb-8 h-100">
          <div className="row h-100 gx-0 gy-10 align-items-center">
            <div
              className="col-lg-6 align-self-end mb-n5 mb-md-n10"
              // data-cues="slideInDown"
              // data-group="page-title"
              // data-delay="600"
            >
              <h4 className="text-black-50 fs-16">FEATURES</h4>
              <h3 className="display-1 fw-normal mb-12">
                <img
                  src={images.cardCoinCycleIcon}
                  alt="page icon"
                  className="me-3 hero-icon"
                />
                Subscriptions
              </h3>
              <p className="lead fs-16 lh-sm mb-7 pe-md-18 pe-lg-0 pe-xxl-15 text-black">
                Create billing models that suit your business and grow your
                revenue across every part of the customer lifecycle.
              </p>
              <div>
                <a
                  href={appConstants.appSignInUrl}
                  className="btn btn-md btn-primary me-3 mt-3 rounded"
                >
                  Sign In
                </a>
                <a
                  href={appConstants.appSignUpUrl}
                  className="btn btn-md btn-outline-primary mt-3 rounded"
                >
                  Request Invitation <i className="fa fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
            {/* <!-- /column --> */}
            <div
              className="col-lg-6 mb-n18 me-n18"
              // data-cues="slideInDown"
            >
              <div className="position-relative light-gallery-wrapper">
                <figure className="cut-off-container">
                  <img
                    src={images.subscriptionSampleScreen}
                    alt="subscription"
                    className="hero-image"
                    style={{ width: 1200, maxWidth: "none" }}
                  />
                </figure>
              </div>
              {/* <!-- /div --> */}
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper mt-10 mt-md-0 position-relative">
        <div className="container pt-25 pt-md-22 pb-5 pb-md-15">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-2">
            <div className="col-lg-6">
              <h3 className="display-4 fw-normal mb-10 pe-xxl-15">
                A Subscription Billing Platform Built to Boost Sales
              </h3>
              <p className="fw-normal text-black">
                SwitchApp’s Subscription Billing Engine provides everything you
                need to reach clients all around the world in a single,
                straightforward integration. Customizable billing plans,
                automatic payment administration, and worldwide optimization
                methods that can help you expand your business abroad.
              </p>
            </div>
            {/* <!-- /column --> */}

            <img
              src={images.subscriptionDetails}
              alt="subscription details"
              className="col-lg-6 mt-lg-2"
            />
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}

        {/* <div className="w-100 h-100 bg-blurred-ellipse position-absolute" /> */}
        {/* blurred background */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light mt-10 mt-md-0">
        <div className="container pt-15 pb-md-10 position-relative">
          <div className="row gy-6 mb-0 mb-md-5 justify-content-center">
            <div className="col-12 text-center">
              {/* <h2 className="fs-16 text-uppercase text-line text-primary mb-3">
                  Our payment experience
                </h2> */}
              <h3 className="display-4 fw-normal mb-3">
                Your one-stop business management engine
              </h3>
            </div>
            {/* <!--/column --> */}

            <img
              src={images.peopleGroup}
              alt="integrate icon"
              className="col-lg-6 one-stop-payment-image"
            />
            {/* <!--/column --> */}

            <div className="col-lg-6">
              <ul className="nav flex-column justify-content-between">
                <BulletTitleDescription
                  title="Flexible Subscription Plans"
                  content={
                    <p>
                      With a dynamic, custom-built subscription plan, you can
                      take control of your pricing model.
                    </p>
                  }
                />

                <BulletTitleDescription
                  title="Automatic Account Updater"
                  content={
                    <p>
                      Outdated account data can halt sales; rescue such sales
                      with automatically updated payment information.
                    </p>
                  }
                />
              </ul>
            </div>
            {/* <!--/column --> */}

            <div className="col-lg-6">
              <ul className="nav flex-column justify-content-between">
                <BulletTitleDescription
                  title="Automated Retries"
                  content={
                    <p>
                      When subscription charges fail, we’ve got your back.
                      Automated retries during a grace period will assist you in
                      capturing more conversions.
                    </p>
                  }
                />

                <BulletTitleDescription
                  title="Subscription Management"
                  content={
                    <p>
                      Delight your clients with features that your competitors
                      don’t have, such as tiered subscriptions plans that you
                      administer, special offers, and more.
                    </p>
                  }
                />
              </ul>
            </div>
            {/* <!--/column --> */}

            <img
              src={images.laptopTyping}
              alt="integrate icon"
              className="col-lg-6 one-stop-payment-image"
            />
            {/* <!--/column --> */}
          </div>
          {/* row */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light mt-5 mt-md-0">
        <div className="container py-10 position-relative">
          <div className="row gy-6 mb-0 mb-md-5 ">
            <div className="col-12 col-lg-6">
              <h3 className="display-4 fw-normal mb-3">
                Equip your team to deliver quality
                <br className="d-none d-lg-block" /> customer service.
              </h3>
              <p className="fw-normal fs-14">
                Give your team access to a holistic view of every customer
                relationship. <br className="d-none d-lg-block" />
                Support teams love the convenience and customers love the faster
                service.
              </p>
            </div>
            {/* <!--/column --> */}

            <div className="col-12">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <SubscriptionPoint
                    icon={
                      <img src={images.peopleIcon} alt="manage relationships" />
                    }
                    title="Manage Relationships"
                    description={
                      <>
                        Access plan changes, billing history, current balances,
                        renewal dates, active coupons, notes, and much more.
                      </>
                    }
                  />
                </div>
                {/* <!--/column --> */}

                <div className="col-lg-6">
                  <SubscriptionPoint
                    icon={
                      <img
                        src={images.convertCardIcon}
                        alt="billing adjustments"
                      />
                    }
                    title="Billing Adjustments"
                    description={
                      <>
                        Access plan changes, billing history, current balances,
                        renewal dates, active coupons, notes, and much more.
                      </>
                    }
                  />
                </div>
                {/* <!--/column --> */}

                <div className="col-lg-6">
                  <SubscriptionPoint
                    icon={
                      <img
                        src={images.documentTextIcon}
                        alt="subscription modifications"
                      />
                    }
                    title="Subscription Modifications"
                    description={
                      <>
                        Change plans, edit customer information and billing
                        information, and change component quantities or price
                        points.
                      </>
                    }
                  />
                </div>
                {/* <!--/column --> */}

                <div className="col-lg-6">
                  <SubscriptionPoint
                    icon={<img src={images.billIcon} alt="process charges" />}
                    title="Process Charges"
                    description={
                      <>
                        Access plan changes, billing history, current balances,
                        renewal dates, active coupons, notes, and much more.
                      </>
                    }
                  />
                </div>
                {/* <!--/column --> */}
              </div>
              {/* <!--/column --> */}
            </div>
            {/* <!--/column --> */}
          </div>
          {/* row */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      {/* bg-gradient-gray */}
      {/* <!-- /section --> */}
    </div>
  );
};
